html, body {
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
}

button {
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;  
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
}
